import * as React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import { LOCAL_URL, THEME } from "../../config";
import PageLayout from "../Global/PageLayout";
import styled from "styled-components";
import { EntityContext } from "../../context";

export interface ISelectAddressProps {}

const AutocompleteContainer = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  input {
    border-radius: 16px;
  }
`;

export default function SelectAddress(props: ISelectAddressProps) {
  const {
    model: entityModel,
    query,
    create,
    remove,
    update,
    replace,
  } = React.useContext(EntityContext);

  const globalData = entityModel["GLOBAL_DATA"].response;

  const [value, setValue] = React.useState(null);

  const histroy = useHistory<any>();

  React.useEffect(() => {
    if (value) {
      replace("GLOBAL_DATA", {
        ...globalData,
        formattedAddress: value.label,
        address: value.label,
      });
      histroy.goBack();
    }
  }, [value]);

  const [addressPlaceholder, setAddressPlaceholder] =
    React.useState("请输入地址");

  React.useEffect(() => {
    if (globalData && globalData.from) {
      const from = globalData.from;
      switch (from) {
        case "merchant":
          setAddressPlaceholder("请输入店铺地址");
          break;
        case "":
          break;
        case "":
          break;
        default:
          break;
      }
    }
  }, [globalData]);

  return (
    <PageLayout
      pageName="no nav"
      baseLay={{ height: 56 * 2, background: THEME.color.primary.default }}
    >
      <Header title="选择地址" />
      <AutocompleteContainer>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyAep_E29x0BmzDguVwVaDhf9x5Q9vpubBk"
          apiOptions={{ region: "nz", language: 'en'}}
          autocompletionRequest={{
            componentRestrictions: {
              country: ["nz"],
            },
          }}
          selectProps={{
            value,
            onChange: setValue,
            placeholder: addressPlaceholder,
            styles: {
              input: (provided) => ({
                ...provided,
                borderRadius: 16,
              }),
              option: (provided) => ({
                ...provided,
              }),
              singleValue: (provided) => ({
                ...provided,
              }),
            },
          }}
        />
      </AutocompleteContainer>
    </PageLayout>
  );
}
